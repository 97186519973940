'use client';

import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import { AskableUIProvider } from 'ui';

import ErrorView from '@/components/Error/Error';
import { MainLayout } from '@/components/Layouts/MainLayout';
import { NextThemeProvider } from '@/context/NextThemeProvider/NextThemeProvider';

const Error = ({ error }: { error: Error }) => {
  useEffect(() => {
    if (error) {
      datadogRum.addError(error);
    }
  }, [error]);
  return (
    <NextThemeProvider>
      <AskableUIProvider>
        <MainLayout>
          <ErrorView />
        </MainLayout>
      </AskableUIProvider>
    </NextThemeProvider>
  );
};

export default Error;
